<template>
    <div id="competition_list">
        <van-image fit="cover" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210615142739675.png" class="back_cover" />
        <div class="time_box">
            <div class="time_tips">距离活动结束还有</div>
            <van-count-down :time="time">
                <template #default="timeData">
                    <span class="block">{{ timeData.days }}天</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                </template>
            </van-count-down>
        </div>
    </div>
</template>

<script>
    export default {
        name: "list",
        data() {
            return {
                time: 30 * 60 * 60 * 1000,
                timeData: {}
            }
        },
        created() {

        },
        methods: {

        }
    }
</script>

<style lang="scss">
    #competition_list {
        .colon {
            display: inline-block;
            margin: 0 4px;
            color: #fff;
        }
        .block {
            display: inline-block;
            min-width: 26px;
            box-sizing: border-box;
            padding-left: 4px;
            padding-right: 4px;
            /*margin-right: 5px;*/
            color: #fff;
            font-size: 12px;
            text-align: center;
            background-color: #221c4c;
            box-shadow: 0 0 10px #494e87;
            border-radius: 8px;
        }
        .back_cover {
            position: fixed;
            z-index: -1;
            top: -41px;
            left: 0;
            width: 100%;
        }

        .time_box {
            position: fixed;
            z-index: 0;
            top: 5px;
            right: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .time_tips {
            color: #fff;
            font-size: 14px;
            margin-bottom: 2px;
        }
    }
</style>